import React from 'react';
import Link from './Link';

const SimpleBanner = ({ name, description, buttonText, Illustration, gotoRoute, imgWidth }) => (
  <div className='simple-banner'>
    <div className='text'>
      <h2>{name}</h2>
      <p>{description}</p>
      {gotoRoute.includes('#') ? (
        <a href={gotoRoute}>
          <button>{buttonText}</button>
        </a>
      ) : (
        <Link to={gotoRoute}>
          <button>{buttonText}</button>
        </Link>
      )}
    </div>
    <div className='illustration'>
      <Illustration width={imgWidth} height='100%' />
    </div>
    <style jsx>{`
      .simple-banner {
        display: flex;
        flex-wrap: wrap;
        margin-top: 100px;
        padding: 20px;
        justify-content: center;
        align-items: center;
      }
      p {
        font-size: 20px;
      }
      .text {
        max-width: 650px;
      }
      .illustration {
        margin: 15px;
      }
    `}</style>
  </div>
);

export default SimpleBanner;
