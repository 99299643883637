import React from 'react';
import Layout from '../../components/Layout';
import Link from '../../components/Link';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import HeaderBanner from '../../components/HeaderBanner.jsx';
import Seo from '../../components/Seo.jsx';
import SimpleBanner from '../../components/SimpleBanner.jsx';
import Laptop from '../../../assets/illustrations/workshop-laptop.svg';
import withTranslations from '../../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';

const Courses = () => {
  const { t } = useTranslation();
  let data = useStaticQuery(graphql`
    query {
      css: file(relativePath: { eq: "courses/past/course-image-03.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      html: file(relativePath: { eq: "courses/past/course-image-09.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      javaScript: file(relativePath: { eq: "courses/past/course-image-08.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      javaFx: file(relativePath: { eq: "courses/past/javafx.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      git: file(relativePath: { eq: "courses/past/git.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      react: file(relativePath: { eq: "courses/react.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      frontend: file(relativePath: { eq: "courses/past/course-image-07.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backend: file(relativePath: { eq: "courses/past/course-image-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      header: file(relativePath: { eq: "courses/past/header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      simpleBanner: file(relativePath: { eq: "courses/past/image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  let coursesData = [
    {
      title: 'Web apps modernas con React',
      description:
        'Aprende a desarrollar sitios y aplicaciones web con la misma tecnologías que utiliza Facebook, Apple y Amazon.',
      imageFluid: data.react.childImageSharp.fluid,
      route: '/courses/react'
    },
    {
      title: t('courses.contents.javaFX.title'),
      description: t('courses.contents.javaFX.description'),
      imageFluid: data.javaFx.childImageSharp.fluid,
      route: '/courses/javafx'
    },
    {
      title: t('courses.contents.git.title'),
      description: t('courses.contents.git.description'),
      imageFluid: data.git.childImageSharp.fluid,
      route: '/courses/git'
    },
    {
      title: t('courses.contents.frontendWebDeveloper.title'),
      description: t('courses.contents.frontendWebDeveloper.description'),
      imageFluid: data.frontend.childImageSharp.fluid,
      route: '/courses/frontend'
    }
  ];

  return (
    <Layout manualMargin manualPadding>
      <Seo
        title={t('courses.seoTitle')}
        description={t('courses.seoDescription')}
        keywords={['javafx', 'git', 'aprender', 'cursos', 'taller', 'workshop', 'courses']}
      />
      <div className='gradient'>
        <HeaderBanner
          title={t('courses.headBanner.title')}
          subtitle={t('courses.headBanner.subtitle')}
          imageFluid={data.header.childImageSharp.fluid}
        />
      </div>
      <div className='main-container global-margin px-4'>
        {coursesData.map((course, index) => (
          <CourseCard
            key={index}
            title={course.title}
            imageFluid={course.imageFluid}
            description={course.description}
            route={course.route}
            upcoming={course.upcoming}
          />
        ))}
      </div>
      <div className='banner-margin'>
        <SimpleBanner
          name={t('courses.simpleBanner.title')}
          description={t('courses.simpleBanner.description')}
          Illustration={Laptop}
          buttonText={t('courses.simpleBanner.button')}
          gotoRoute={`#contact>${t('contact.selectProjects.options.courses')}`}
          imgWidth='150'
        />
      </div>
      <style jsx>{`
        .main-container {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          grid-gap: 40px;
          justify-items: center;
          margin-top: 90px;
          margin-bottom: 170px;
        }
        .center {
          text-align: center;
        }
        .gradient {
          background: linear-gradient(180deg, #262626 0%, rgba(0, 0, 0, 0.35) 100%);
        }
        .banner-margin {
          margin-bottom: 200px;
        }
      `}</style>
    </Layout>
  );
};

const CourseCard = ({ imageFluid, title, description, route, upcoming }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='main-container'>
        <Img
          alt={`${title} illustration`}
          fluid={imageFluid}
          style={{ height: '150px' }}
          imgStyle={{ objectPosition: '50% 0%', objectFit: 'cover' }}
        />
        <div className='text-container'>
          <span className='title'>{title}</span>
          <span className='description'>{description}</span>

          <div className='flex'>
            {upcoming ? (
              <span className='upcoming'>{t('courses.cards.comingSoon')}</span>
            ) : (
              <Link to={route}>
                <div className='more-info'>{t('courses.cards.seeMore')}</div>
              </Link>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .flex {
          display: flex;
          justify-content: flex-end;
        }

        .main-container {
          display: flex;
          flex-direction: column;
          max-width: 350px;
        }

        .text-container {
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
          background-color: #3a3a3a;
          min-height: 120px;
          display: flex;
          flex-direction: column;
          padding: 10px;
        }

        .title {
          font-size: 18px;
          color: #ffa500;
          font-weight: 600;
        }

        .description {
          font-size: 14px;
        }

        .more-info {
          text-align: right;
          font-size: 12px;
          color: #ffa500;
          text-decoration: underline;
          cursor: pointer;
        }

        .upcoming {
          background-color: #b32100;
          border-radius: 5px;
          padding: 5px;
          display: flex;
          font-size: 10px;
          font-weight: bold;
        }

        @media only screen and (max-width: 360px) {
          .main-container {
            max-width: auto;
            padding: 0px 6px 0px 6px;
          }
        }
      `}</style>
    </>
  );
};

export default withTranslations()(Courses);
